import { VoiceOverLocales } from '../pages/Admin/VoiceOverLocales';

function VoiceOverLocalesRoute() {
  return <VoiceOverLocales />;
}

export const Component = VoiceOverLocalesRoute;

export function clientLoader() {
  return null;
}
